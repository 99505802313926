<template>
  <div class="contentSuiviRegie">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style  bg-select"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2">
          <b-form-checkbox
            v-show="switchMonth == false"
            v-model="switchMonth"
            @change="handleChangeSwitch('switchWeek')"
            name="check-button"
            switch
            class="check-th"
            :value="true"
            :unchecked-value="false"
          >
            <b class="mt-1 switch-label-style">Par Mois </b>
          </b-form-checkbox>
          <b-form-checkbox
            v-show="switchWeek == false"
            v-model="switchWeek"
            @change="handleChangeSwitch('switchMonth')"
            name="check-button"
            switch
            class="check-th"
            :value="true"
            :unchecked-value="false"
          >
            <b class="mt-1 switch-label-style">Par Semaine </b>
          </b-form-checkbox>
        </div>
        <div class="box-label-champ mr-2" v-if="switchMonth == true">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterMonth"
            :options="getListMonth"
            @change="handleChangeMonth"
          ></b-form-select>
        </div>
        <div class="box-label-champ mr-2" v-if="switchWeek == true">
          <div class="label-box-style">
            <span class="title-tabel">Semaine</span>
          </div>

          <b-form-select
            class="b-form-select-new-style  bg-select"
            v-model="filterSemaine"
            :options="ComputedListWeek"
            @change="handleChange"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Régie</span>
          </div>

          <SelectComponent
            :options="computedgetAllRegie"
            :value="filterRegie"
            label="name"
            champName="régie"
            filterName="filterRegie"
            :change="handleChange"
            :track-by="'name'"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style w-1  bg-select-vue"
          />
        </div>
        <div class="box-label-champ mr-2">
          <div class="label-box-style  ">
            <span class="title-tabel">Statut</span>
          </div>

          <b-form-select
            class="b-form-select-new-style bg-select w-1"
            v-model="filterValidation"
            :options="validationList"
            value-field="value"
            text-field="text"
            @change="handleChange"
          ></b-form-select>
        </div>

        <div v-if="getChargementSuiviRegie" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <b-button
            v-if="cantExport(this.$options.name)"
            size="sm"
            class="button-export-style  ml-2"
            @click="exportFiles"
            >Export</b-button
          >
        </div>
      </div>
    </div>
    <div class="body-box-rapport">
      <div class="table-rapport-style w-100-p">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style tableSuiviRegie  table-header-regie"
          :items="computedGetSuiviRegieTable"
          :fields="fields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          :tbody-tr-class="rowClass"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template #thead-top="">
            <b-tr>
              <b-th
                variant="danger"
                colspan="7"
                class=" th-rouge-bg-color th-border-raduis-left"
                >AAF REGIE</b-th
              >
              <b-th variant="primary" colspan="5" class="th-jaune-bg-color  ">
                Raison sociale</b-th
              >
              <b-th variant="primary" colspan="2" class="th-jaune-bg-color  ">
                COMTABILITE</b-th
              >
              <b-th
                variant="primary"
                colspan="2"
                class="th-jaune-bg-color  th-border-raduis-rigth"
              >
                PAIEMENT</b-th
              >
            </b-tr>
          </template>
          <template v-slot:cell(num_aaf)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(regie)="data">
            {{ data.item.regie }}
          </template>
          <template v-slot:cell(semaine)="data">
            {{ data.item.semaine }}
          </template>
          <template v-slot:cell(m2)="data">
            {{ data.item.m2 }}
          </template>
          <template v-slot:cell(total)="data">
            {{ data.item.total }} €
          </template>
          <template v-slot:cell(prix_monyen)="data">
            {{ data.item.prix_monyen }} €
          </template>
          <template v-slot:cell(date_envoi)="data">
            {{ data.item.date_envoi }}
          </template>
          <template v-slot:cell(raison_social)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="raison_social"
              :item="data.item"
              :value="data.item.raison_social"
              type="text"
              editableType="input"
              :updateFunction="updateSuiviRegie"
              :defaultColor="true"
            />
          </template>
          <template v-slot:cell(SEPA)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="SEPA"
              editableType="select"
              :optionsSelect="[{ full_name: 'oui' }, { full_name: 'non' }]"
              :item="data.item"
              :value="data.item.SEPA"
              type="text"
              :updateFunction="updateSuiviRegie"
              :defaultColor="true"
            />
          </template>
          <template v-slot:cell(s_monatant_ttc)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="s_monatant_ttc"
              :item="data.item"
              :value="data.item.s_monatant_ttc"
              type="text"
              editableType="input"
              :updateFunction="updateSuiviRegie"
              :defaultColor="true"
            />
          </template>
          <template v-slot:cell(reference_facture)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="reference_facture"
              :item="data.item"
              :value="data.item.reference_facture"
              type="text"
              editableType="input"
              :updateFunction="updateSuiviRegie"
              :defaultColor="true"
            />
          </template>
          <template v-slot:cell(date_de_facture)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="date_de_facture"
              :item="data.item"
              :value="data.item.date_de_facture"
              type="date"
              editableType="datetime"
              :updateFunction="updateSuiviRegie"
            />
          </template>
          <template v-slot:cell(saisie_comptable)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="saisie_comptable"
              editableType="select"
              :optionsSelect="[{ full_name: 'oui' }, { full_name: 'non' }]"
              :item="data.item"
              :value="data.item.saisie_comptable"
              type="text"
              :updateFunction="updateSuiviRegie"
              :defaultColor="true"
            />
          </template>
          <template v-slot:cell(date_saisie)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="date_saisie"
              :item="data.item"
              :value="data.item.date_saisie"
              type="date"
              editableType="datetime"
              :updateFunction="updateSuiviRegie"
            />
          </template>
          <template v-slot:cell(statue)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="statue"
              editableType="select"
              :optionsSelect="[{ full_name: 'paye' }]"
              :item="data.item"
              :value="data.item.statue"
              type="text"
              :updateFunction="updateSuiviRegie"
            />
          </template>
          <template v-slot:cell(date)="data">
            <EditableInput
              :editable="computedCantUpdate"
              champName="date"
              :item="data.item"
              :value="data.item.date"
              type="date"
              editableType="datetime"
              :updateFunction="updateSuiviRegie"
            />
          </template>
        </b-table>

        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowSuiviRegie"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'Suivi-des-régies',
  data() {
    return {
      switchMonth: false,
      switchWeek: true,
      filterMonth: 1,
      filterRegie: { name: 'tous', id: null },
      filteryear: null,
      filterSemaine: null,
      ListYear: [],
      ListWeek: [],
      page: 1,
      perPage: 10,
      filterValidation: null,
      fields: [
        { key: 'num_aaf', label: 'N° AAF' },
        { key: 'regie', label: 'NOM REGIE' },
        {
          key: 'semaine',
          label: 'SEMAINE'
        },
        { key: 'm2', label: 'M²' },
        { key: 'total', label: 'TOTAL HT' },
        { key: 'prix_monyen', label: 'PRIX MOYEN' },

        {
          key: 'date_envoi',
          label: "DATE D'ENVOI"
        },
        {
          key: 'raison_social',
          label: 'RAISON SOCIALE'
        },
        {
          key: 'SEPA',
          label: 'SEPA'
        },
        {
          key: 's_monatant_ttc',
          label: 'MONTANT TTC'
        },

        {
          key: 'reference_facture',
          label: 'RÉFÉRENCE FACTURE'
        },
        {
          key: 'date_de_facture',
          label: 'DATE DE FACTURE'
        },
        {
          key: 'saisie_comptable',
          label: 'SAISIE COMPTABLE'
        },
        {
          key: 'date_saisie',
          label: 'DATE DE SAISIE'
        },

        {
          key: 'statue',
          label: 'ETAT  '
        },
        {
          key: 'date',
          label: 'DATE'
        }
      ],
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      validationList: [
        { value: null, text: 'tous' },
        { value: 'valid', text: 'appel à facturation validé' },
        { value: 'paye', text: 'Payé' }
      ]
    };
  },
  methods: {
    ...mapActions([
      'getSuiviRegieFiltred',
      'fetchAllRegieForAdmin',
      'updateSuiviRegie',
      'exportExcelSuiviRegie'
    ]),
    handleChangeMonth() {
      this.FilterSuiviRegie();
    },
    handleChangeSwitch(item) {
      if (item == 'switchMonth') {
        this.switchMonth = false;
      } else {
        this.switchWeek = false;
      }
      this.FilterSuiviRegie();
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return;
      let index = this.getSuiviRegieTable.findIndex(i => i.id == item.id);
      if (
        index != -1 &&
        index < this.getSuiviRegieTable.length &&
        this.getSuiviRegieTable[index] &&
        this.getSuiviRegieTable[index + 1]
      ) {
        if (
          this.getSuiviRegieTable[index].semaine !=
          this.getSuiviRegieTable[index + 1].semaine
        ) {
          return 'ligneWithBorder';
        } else {
          return 'ligneNormale';
        }
      } else {
        return 'ligneNormale';
      }
    },
    handleChange(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      this.page = 1;
      this.FilterSuiviRegie();
    },
    handleChangeYear() {
      this.page = 1;
      var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
      this.FilterSuiviRegie();
    },
    pagination(paginate) {
      this.page = paginate;
      this.FilterSuiviRegie();
    },
    changePerPage() {
      this.page = 1;
      this.FilterSuiviRegie();
    },
    FilterSuiviRegie() {
      this.getSuiviRegieFiltred({
        semaine: this.filterSemaine,
        year: this.filteryear,
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage,
        validation: this.filterValidation,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
    },
    async exportFiles() {
      await this.exportExcelSuiviRegie({
        semaine: this.filterSemaine,
        year: this.filteryear,
        filterRegie: this.filterRegie,
        page: this.page,
        per_page: this.perPage,
        validation: this.filterValidation
      });
    }
  },

  computed: {
    ...mapGetters([
      'getChargementSuiviRegie',
      'getTotalRowSuiviRegie',
      'getSuiviRegieTable',
      'getAllRegie',
      'cantExport',
      'cantUpdate',
      'getListMonth'
    ]),
    computedCantUpdate() {
      return this.cantUpdate(this.$options.name);
    },
    ComputedListWeek() {
      return [{ value: null, text: 'tous' }, ...this.ListWeek];
    },
    computedGetSuiviRegieTable() {
      return this.getSuiviRegieTable;
    },
    computedgetAllRegie() {
      return [{ id: null, name: 'tous' }, ...this.getAllRegie];
    }
  },
  components: {
    SelectComponent: () => import('../SelectComponent'),
    EditableInput: () => import('../component/EditableInput.vue')
  },
  mounted() {
    var courantYear = moment().year();
    this.filteryear = courantYear;
    this.filterSemaine = 's' + moment().isoWeek();
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
    for (var week = 1; week <= totalWeek; week++) {
      this.ListWeek.push({ value: 's' + week, text: week });
    }
    this.fetchAllRegieForAdmin();
    this.FilterSuiviRegie();
  }
};
</script>

<style scoped lang="scss">
.contentSuiviRegie {
  padding: 10px !important;
  margin: 0px !important;
  height: calc(100vh - 110px);
  display: block;
  .entete {
    width: 100%;
    .chargement {
      font-size: 8px;
      margin-left: 5px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .body-box-rapport {
    height: calc(100vh - 175px);
  }
}
.tableSuiviRegie {
  max-height: calc(100vh - 210px) !important;
  height: calc(100vh - 266px) !important;
  margin-bottom: 0px;
  td {
    padding: 5px;
  }
}
</style>
<style lang="scss">
.tableSuiviRegie {
  td,
  th {
    white-space: nowrap !important;
    width: auto !important;
    min-width: auto;
    padding: 6px 5px !important;
  }
  th {
    font-size: 9.5px !important;
  }
  td {
    font-size: 10px;
  }
  .th-rouge-bg-color {
    background-color: #e9999c !important;
  }
  .th-jaune-bg-color {
    background-color: #f5c12dd4 !important;
  }
  .th-border-raduis-left {
    border-radius: 10px 0px 0px 0px;
  }
  .th-border-raduis-rigth {
    border-radius: 0px 10px 0px 0px;
  }
}
</style>
